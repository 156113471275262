export interface User {
    [key: string]: any
    id: string,
    userName: string,
    email: string,
    firstName: string,
    lastName: string,
    clientId: number,
    clientName: string,
    isActive: boolean,
    role: string,    
}

export const defaultUser: User = {
    id: "",
    userName: "",
    email: "",
    firstName: "",
    lastName: "",
    clientId: 0,
    clientName: "",
    isActive: true,
    role: "Editor"
}