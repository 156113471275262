import * as React from "react";
import * as ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import App from "./containers/App";
import { configAxios } from "./util/axios";
import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./features/root-reducer";
import { initialState } from "./interfaces/general/App-State";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import { theme } from "./theme/theme";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SuspenseScreen from "./containers/SuspenseScreen";
import { Suspense } from "react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMoment from '@mui/lab/AdapterMoment';

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme { }
}

const w: any = window as any;
const devtools: any = w.__REDUX_DEVTOOLS_EXTENSION__
    ? w.__REDUX_DEVTOOLS_EXTENSION__()
    : (f: any) => f;

const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(thunk), devtools)
);

configAxios();

ReactDOM.render(
    <Provider store={store}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Suspense fallback={<SuspenseScreen />}>
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                    />
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Router history={createBrowserHistory()}>
                            <App />
                        </Router >
                    </LocalizationProvider>
                </Suspense>
            </ThemeProvider>
        </StyledEngineProvider>
    </Provider>,
    document.getElementById("root")
);