import { Request } from "../interfaces/general/Request";
import { ActionType, createAction } from "typesafe-actions";

const request = createAction("_REQUEST")();
const success = createAction("_SUCCESS")();
const failure = createAction("_FAILURE")();

export type RequestActions = ActionType<
    typeof request | typeof success | typeof failure
>;

function getActionName(actionType: string) {
    if (typeof actionType !== "string") {
        return null;
    }

    return actionType.split("_").slice(0, -1).join("_");
}

function updateRequest(state: Request[], actionName: string, pending: boolean, status: string) {
    let updatedState = [...state];
    let request = updatedState.filter((x) => x.type === actionName).length > 0
        ? updatedState.filter((x) => x.type === actionName)[0]
        : null;
    let pos = request ? updatedState.indexOf(request) : 0;
    if (request) {
        request.pending = pending;
        request.status = status;
        updatedState[pos] = request;
    } else {
        request = {
            type: actionName,
            pending: pending,
            status: status,
        };
        updatedState.push(request);
    }
    return updatedState;
}

export const requestReducer = (
    state: Request[] = [],
    action: RequestActions
) => {
    const { type } = action;
    const actionName = getActionName(type);

    if (actionName) {
        if (type.endsWith("_SUCCESS")) {
            return updateRequest(state, actionName, false, "SUCCESS");
        } else if (type.endsWith("_FAILURE")) {
            return updateRequest(state, actionName, false, "FAILURE");
        } else if (type.endsWith("_REQUEST")) {
            return updateRequest(state, actionName, true, "REQUEST");
        }
    }

    return [...state];
};
