import React, { useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "../../features/user-feature";
import { useLocation, useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";

const CheckLoggedInUser: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const isLoginRelatedPage = location.pathname.startsWith("/login") || location.pathname === "/logout";
    const [openDialog, setOpenDialog] = useState(false);

    const fetchUser = useCallback(async () => {
        if (!isLoginRelatedPage) {
            try {
                await dispatch(getCurrentUser());
            } catch (error) {
                console.log(error);
                if(error != undefined){
                    toast.error(`An error occured. Please try again later or contact support`);
                }
            }
        }
    }, [dispatch, isLoginRelatedPage]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    useEffect(() => {
        const checkLoginStatus = async () => {
            if (!isLoginRelatedPage) {
                try {
                    await dispatch(getCurrentUser());
                } catch (error) {
                    setOpenDialog(true);
                }
            }
        };
        const intervalId = setInterval(checkLoginStatus, 5 * 1000);
        return () => clearInterval(intervalId);
    }, [dispatch, isLoginRelatedPage, fetchUser]);

    const handleDialogClose = (_event: React.SyntheticEvent<{}, Event>, reason?: string) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setOpenDialog(false);
            history.push("/logout");
        }
    };

    return (
        <>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableEscapeKeyDown
            >
                <DialogTitle id="alert-dialog-title">{"You have been logged off"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your session has expired. Please log in again.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(null, "close")} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CheckLoggedInUser;
