
import { ConfirmProvider } from "material-ui-confirm";
import * as React from "react";
import { useSelector } from "react-redux";
import Header from "../components/header/Header";
import { AppState } from "../interfaces/general/App-State";
import { User } from "../interfaces/User";
import Routes from "../routes";
import { useMainStyles } from "../theme/styles/main";
import { } from 'react-redux';
import CheckLoggedInUser from "../components/user-management/CheckLoggedInUser";

const App: React.FC = () => {
    const classes = useMainStyles();
    const user = useSelector<AppState, User>(x => x.user);
    
    return (
        <>
            <ConfirmProvider>
                <CheckLoggedInUser />
                {user && user.id && <Header /> || null}
                <main className={user && user.id ? classes.content : classes.login}>
                    <Routes />
                </main>
            </ConfirmProvider>
        </>
    );
}

export default App;