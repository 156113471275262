import * as React from "react";
import { useSelector } from "react-redux";
import { User } from "../../interfaces/User";
import { AppState } from "../../interfaces/general/App-State";
import { isAdministrator, isClientAdministrator } from "../../util/authorization";

interface Props {
    children: React.ReactNode;
    countClientAdministratorAsAdmin: boolean;
    fallbackText?: string;
}

const ProtectedContent: React.FC<Props> = (props: Props) => {
    const currentUser = useSelector<AppState, User>((s) => s.user);

    return isAdministrator(currentUser) || (props.countClientAdministratorAsAdmin && isClientAdministrator(currentUser)) ? (
        <>{props.children}</>
    ) : props.fallbackText ? (
        <small style={{ textAlign: "center" }}>{props.fallbackText}</small>
    ) : null;
};

export default ProtectedContent;