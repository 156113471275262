import * as React from "react";
import BarLoader from "react-spinners/BarLoader";

const SuspenseScreen: React.FC = () => {
  return (
    <div className="suspenseScreen">        
      <img src="/public/img/logo.svg" />
      <h1>The application is being prepared</h1>
      <h3>Check your internet connection if this message doesn't disappear.</h3>
      <div className="suspenseLoader"><BarLoader loading width="100%"/></div>
    </div>
  );
};

export default SuspenseScreen;
