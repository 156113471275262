import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { drawerWidth } from "../../components/header/Header";

export const useMainStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: "block",
            flexGrow: 1,
            [theme.breakpoints.up("lg")]: {
                width: `calc(100vw - ${drawerWidth + 50}px)`,
                marginLeft: drawerWidth,
                padding: "25px 0 25px 25px"
            },
            marginTop: "64px",
            padding: "25px"
        },
        login: {
            background: theme.palette.primary.main,
            position: "relative",
            height: "100vh",
            backgroundSize: "140%",
            backgroundPosition: "center -200px",
            backgroundImage: "url('/assets/img/bg_hubspot_login.svg')",
            backgroundRepeat: "no-repeat",
            [theme.breakpoints.up("sm")]: {
                backgroundPosition: "center -350px",
                backgroundSize: "auto",
            },
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(3),
            right: theme.spacing(3),
        },
    })
);