import axios from "axios";
import { toast } from 'react-toastify';
import appSettings from "../app-settings";

export const configAxios = () => {
  axios.defaults.baseURL = appSettings.apiPath;
  axios.interceptors.request.use(function (config) {
    const token = `bearer ${localStorage.getItem("jwtToken")}`;
    config.headers.Authorization = token;

    return config;
  });

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 400 || error.response.status === 401) {
        // custom BadRequest messages that guide the user into the direction (return BadRequest("message"))
        error.response.data && toast.error(`${error.response.data}`);
      } else {
        toast.error(`${error.message}`);
      }

      return Promise.reject(error);
    }
  );
};
