import axios from "axios";
import { UserManagementFilter } from "../interfaces/filters/UserManagementFilter";
import { User } from "../interfaces/User";

export const get = (userId: string) => {
    return axios.get(`/userManagement/get?id=${userId}`)
}

export const getCurrent = () => {
    return axios.get("/userManagement/getCurrent");
}

export const getAllPaginated = (filter: UserManagementFilter) => {
    return axios.get(`/userManagement/getAllPaginated?skip=${(filter.currentPage - 1) * filter.itemsPerPage}&itemsPerPage=${filter.itemsPerPage}`)
}

export const add = (user: User) => {
    return axios.post("/userManagement/add", user);
}

export const update = (user: User) => {
    return axios.post("/userManagement/update", user);
}

export const remove = (userId: string) => {
    return axios.delete(`/userManagement/delete?id=${userId}`)
}